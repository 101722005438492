.external-server-cont {
    width: 100%;
}

.create-external-server-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    height: fit-content;
    padding-bottom: 10px;
}

.create-external-server-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 22px;
    width: 100%;
}

.external-server-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-top: 15px;
    padding-bottom: 10px;
}

.external-server-single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 15px;
    background-color: rgba(0, 0, 0, 0.35);
    border-radius: 3px;
    cursor: pointer;
}

.external-server-single.active {
    background-color: var(--hover-blue);
}

.server-single-name {
    font-size: 14px;
    font-weight: 600;
}

.server-single-type {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-yellow);
    color: var(--dark-blue);
    height: 25px;
    width: 50px;
    border-radius: 2px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
}

.server-single-type.sftp {
    background-color: rgb(255, 75, 0);
    color: var(--dark-blue);
}

.server-details-cont {
    width: 100%;
}

.external-server-port {
    font-size: 14px;
    font-weight: 600;
}

.server-private-key-input {
    padding-top: 10px;
}

.create-external-server-btn {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 12px;
    margin-right: 10px;
}

.no-server-selected-icon {
    font-size: 25px;
}

/* HOVER */
.external-server-single:hover {
    background-color: var(--hover-blue);
}

/* MEDIA QUERY */
@media screen and (max-width: 480px) {
    .server-single-name {
        font-size: 13px;
        font-weight: 600;
    }
    .server-single-type {
        font-size: 12px;
    }
}